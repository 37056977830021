<template>
    <!-- 受邀人同意 -->
    <div>
        <!--    状态0-->
        <div class="appointment-time" v-if="type === 0">
            <!-- 翻译：预约时间-->
            <h1>{{ $t("vipRecommend.appointmentTime") }}</h1>
            <p class="tip">{{ $t("myInterviewDetail.selectTime") }}<!--请选择一个时间段接受您的预约申请--></p>
            <ul class="time-list">
                <li @click="select_fun(details_data.invite_time1)">
                    <label :class="{gray:isCan(details_data.invite_time1)}" class="radio-label" for="a"
                    ><input :disabled="isCan(details_data.invite_time1)" id="a" name="time" type="radio"/>
                        {{ details_data.invite_time1 }}
                    </label>
                </li>
                <li @click="select_fun(details_data.invite_time2)">
                    <label :class="{gray:isCan(details_data.invite_time2)}" class="radio-label" for="b"
                    ><input :disabled="isCan(details_data.invite_time2)" id="b" name="time" type="radio"/> {{
                        details_data.invite_time2 }}
                    </label>
                </li>
                <li @click="select_fun(details_data.invite_time3)">
                    <label :class="{gray:isCan(details_data.invite_time3)}" class="radio-label" for="c"
                    ><input :disabled="isCan(details_data.invite_time3)" id="c" name="time" type="radio"/> {{
                        details_data.invite_time3 }}
                    </label>
                </li>
                <li @click="select_fun(details_data.invite_time4)">
                    <label :class="{gray:isCan(details_data.invite_time4)}" class="radio-label" for="d"
                    ><input :disabled="isCan(details_data.invite_time4)" id="d" name="time" type="radio"/> {{
                        details_data.invite_time4 }}
                    </label>
                </li>
                <li @click="select_fun(details_data.invite_time5)">
                    <label :class="{gray:isCan(details_data.invite_time5)}" class="radio-label" for="e"
                    ><input :disabled="isCan(details_data.invite_time5)" id="e" name="time" type="radio"/> {{
                        details_data.invite_time5 }}
                    </label>
                </li>
            </ul>

            <div class="leave-message">
                <h1>{{ $t("publish.kefuliuyan") }}<!--关于预约给客服的留言--></h1>
                <textarea :placeholder="$t('publish.input')" cols="30" id="" name="" rows="10" v-model="many.remarks"><!--请输入-->
        </textarea>
            </div>
            <div class="is-btn-group">
                <div @click="dialogVisible = true; pd_post = 0;">
                    <CustomButton @click="sendMsg" class="fl" type="black">{{ $t("message.tongyi") }}
                        <!--同意--></CustomButton>
                </div>
                <div @click="dialogVisible = true; pd_post = 1;">
                    <CustomButton @click="sendMsg" class="fl" style="margin-left: 17px" type="black">{{
                        $t("myProject.refuse") }}<!--拒绝-->
                    </CustomButton>
                </div>
            </div>
        </div>
        <!--    状态1 取消预约、访谈-->
        <div class="appointment-time" v-else-if="type === 1">
            <h1>{{ $t("vipRecommend.appointmentTime") }}</h1>
            <div class="time">{{ details_data.invitation_time }}</div>
            <div class="s-leave-message">
                <!--翻译：关于预约给客服的留言-->
                <h2>{{ $t("vipRecommend.messageAboutBookingToCustomerService") }}</h2>
                <p class="over4">
                    {{ details_data.invite_message }}
                </p>
            </div>
            <div class="cancel-yy" style="display: flex" v-show="details_data.isShow == 1">
                <div @click="pd_post = 2; dialogVisible = true;">
                    <CustomButton class="btn" type="black">{{$t('myProject.cancelReservation')}}</CustomButton>
                </div>
            </div>
            <!--{vip访谈、其他}-->
            <CustomButton @click.native="$router.push({path:'/videoRoom',
                    query:{interviewId:details_data.interview_id||details_data.id, type:details_data.interviewType||details_data.type, isPublisher:0,}})"
                          class="btn"
                          style="margin-top: 20px; background-color: #F1F1F1; font-weight: bold; font-size: 24px;"
                          type="white" v-show="details_data.isFanTan == 1">
                {{$t('myProject.interview')}}
            </CustomButton>
            <p style="margin-top: 20px">{{$t("bulletFrame.appointmentTwoHours")}}</p>
        </div>
        <!--    状态2 评价-->
        <div class="appointment-time" v-else-if="type === 2&&isEvaluate==0">
            <h1>{{ $t("vipRecommend.appointmentTime") }}</h1>
            <div class="time">{{ details_data.invitation_time }}</div>
            <div class="s-leave-message">
                <!--翻译：关于预约给客服的留言-->
                <h2>{{ $t("vipRecommend.messageAboutBookingToCustomerService") }}</h2>
                <p class="over4">
                    {{ details_data.invite_message }}
                </p>
            </div>
            <div class="people-wrap">
                <!-- <img class="people-head" src="@/assets/images/1.png" alt="" /> -->
                <img :src="details_data.avatar" alt="" class="people-head"/>

                <span class="people-name">{{ details_data.name }}</span>
                <span class="project-name">{{ details_data.project_name }}</span>
            </div>
            <div class="evaluate">
                <div class="evaluate-star">
                    <span>{{$t('myProject.evaluationInterview')}}<!--本次访谈评价--></span>
                    <el-rate :colors="colors" :max="3" class="star" v-model="star" void-color="#D6D6D6"></el-rate>
                </div>
                <div class="evaluate-content">
          <textarea :placeholder="$t('publish.input')" @input="txtChange" cols="30"
                    id="evaluate" maxlength="140" name="evaluate" rows="10" v-model="many.content"></textarea>
                    <span class="numberV">{{ txtVal }}/140</span>
                </div>
            </div>
            <div @click="post_comment" class="dialog-footer" slot="footer">
                <CustomButton style="margin-top: 31px" type="black">{{$t('myProject.submit')}}<!--提交--></CustomButton>
            </div>
        </div>
        <!--    状态3 已评价 type == 3-->
        <div class="appointment-time" v-else-if="(type == 3||type == 2)&&isEvaluate==1">
            <h1>{{ $t("vipRecommend.appointmentTime") }}</h1>
            <div class="time">{{ details_data.invitation_time }}</div>
            <div class="s-leave-message">
                <!--翻译：关于预约给客服的留言-->
                <h2>{{ $t("vipRecommend.messageAboutBookingToCustomerService") }}</h2>
                <p class="over4">
                    {{ details_data.invite_message }}
                </p>
            </div>
            <div class="evaluate-result">
                <div @click="editComment" class="edit">
                    <img alt="" src="@/assets/images/edit@2x.png" v-if="!isEditing">
                    <span>{{ !isEditing ? $t('myProject.editor') : $t('myProject.submit')}}</span>
                </div>
                <h1>{{$t('myProject.evaluationInterview')}}<!--本次访谈评价--></h1>
                <div class="evaluate-result-content">
                    <img :src="details_data.invitee_image" alt=""/>
                    <div class="erc-r fl">
                        <div class="erc-r-t">
                            <div class="erc-r-t-l">
                                <div class="erc-r-t-l-name">{{ details_data.invitee_name }}</div>
                                <el-rate :colors="colors" :disabled="!isEditing" :max="3" v-model="ratingEdit"
                                         void-color="#D6D6D6"></el-rate>
                            </div>
                            <div class="erc-r-t-r">{{ details_data.comment_time }}</div>
                        </div>
                        <div class="erc-r-b over4" v-if="!isEditing">
                            {{ details_data.comment_content }}
                        </div>
                        <div v-else>
                            <div class="evaluate-content">
                <textarea :placeholder="$t('publish.input')" @input="txtChangeForEdit" cols="30" id="a1" maxlength="140"
                          name="evaluate"
                          rows="10" v-model="contentEdit"></textarea>
                                <span class="numberV">{{ contentEdit.length }}/140</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--    状态4-->
        <div class="appointment-time" v-else-if="type === 4">
            <h1>{{ $t("vipRecommend.appointmentTime") }}</h1>
            <div class="time">{{ details_data.invitation_time }}</div>
            <div class="form-wrap">
                <div class="input-text-area">
                    <div class="ita-label">
                        <span>* </span>{{ $t("vipRecommend.mobileNumber") }}
                    </div>
                    <div>
                        <div class="area-code" style="margin-top: 15px;width: 536px">
                            <el-input :placeholder="$t('publish.input')" class="input-with-select" v-model="many.phone">
                                <el-select :placeholder="$t('message.pleaseSelect')" filterable slot="prepend"
                                           v-model="many.mobileCode">
                                    <el-option :key="item.id" :label="'+'+item.code + '(' + item.countrysEnglish + ')'"
                                               :value="item.code"
                                               v-for="item in areaList.phoneCountriesCodeList"></el-option>
                                </el-select>
                            </el-input>
                        </div>
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>{{ $t("vipRecommend.email") }}</div>
                    <div class="ita-input-front">
                        <input :placeholder="$t('publish.input')" class="iif-input" type="text"
                               v-model="many.data_mailbox"/>
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>WhatsApp</div>

                    <div class="area-code" style="margin-top: 15px;width: 536px">
                        <el-input :placeholder="$t('publish.input')" class="input-with-select"
                                  v-model="many.data_whatsApp">
                            <el-select :placeholder="$t('message.pleaseSelect')" filterable slot="prepend"
                                       v-model="many.whatsappCode">
                                <el-option :key="item.id" :label="'+'+item.code + '(' + item.countrysEnglish + ')'"
                                           :value="item.code"
                                           v-for="item in areaList.phoneCountriesCodeList"></el-option>
                            </el-select>
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="leave-message">
                <h1>{{ $t("publish.kefuliuyan") }}<!--关于预约给客服的留言--></h1>
                <textarea :placeholder="$t('publish.input')" cols="30" id="q" name="" rows="10"
                          v-model="many.content"> </textarea>
            </div>
            <div class="is-btn-group">
                <div @click="sendMsg(1)">
                    <CustomButton class="fl" type="black">{{ $t("message.tongyi") }}<!--同意--></CustomButton>
                </div>
                <div @click="sendMsg(2)">
                    <CustomButton class="fl" style="margin-left: 17px" type="black">{{ $t("myProject.refuse") }}
                        <!--拒绝--></CustomButton>
                </div>
            </div>
        </div>

        <el-dialog
                :title="pd_post == 0 ? $t('bulletFrame.agreeToMakeAnAppointment') : pd_post == 1 ? $t('vipRecommend.reasonsForRefuse') : $t('bulletFrame.reasonForCancellation')"
                :visible.sync="dialogVisible"
                center custom-class="title-class" width="50%">
            <div style="display: flex; justify-content: center">
                <div class="form-wrap">
                    <div class="input-text-area" v-if="pd_post == 0 || pd_post == 1">
                        <div class="ita-label">
                            <span>* </span>{{ $t("vipRecommend.mobileNumber") }}
                        </div>

                        <div class="area-code" style="margin-top: 15px;">
                            <el-input :placeholder="$t('publish.input')" class="input-with-select" v-model="many.phone">
                                <el-select :placeholder="$t('message.pleaseSelect')" filterable slot="prepend"
                                           v-model="many.mobileCode">
                                    <el-option :key="item.id"
                                               :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)"
                                               :value="item.code"
                                               v-for="item in areaList.phoneCountriesCodeList"></el-option>
                                </el-select>
                            </el-input>
                        </div>
                    </div>
                    <div class="input-text-area" v-if="pd_post == 0 || pd_post == 1">
                        <div class="ita-label"><span>* </span>{{ $t("vipRecommend.email") }}</div>
                        <div class="ita-input-front">
                            <input :placeholder="$t('publish.input')" class="iif-input" type="text"
                                   v-model="many.data_mailbox"/>
                        </div>
                    </div>
                    <div class="input-text-area">
                        <div v-if="pd_post == 0 || pd_post == 1">
                            <div class="ita-label"><span>* </span>WhatsApp</div>
                            <div class="area-code" style="margin-top: 15px;">
                                <el-input :placeholder="$t('publish.input')" class="input-with-select"
                                          v-model="many.data_whatsApp">
                                    <el-select :placeholder="$t('message.pleaseSelect')" filterable slot="prepend"
                                               v-model="many.whatsappCode">
                                        <el-option :key="item.id"
                                                   :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)"
                                                   :value="item.code"
                                                   v-for="item in areaList.phoneCountriesCodeList"></el-option>
                                    </el-select>
                                </el-input>
                            </div>
                        </div>
                        <div class="input-text-area" v-if="pd_post == 1 || pd_post == 2">
                            <div class="ita-input-front" style="border-radius: 4px; height: 100%">
                <textarea :placeholder="$t('bulletFrame.cancelReason')" @input="txtChange" cols="30" name="evaluate"
                          rows="10"
                          style="background-color: #fafafa; width: 100%; height: 149px; margin-top: 17px;"
                          v-model="many.content">

                </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span class="dialog-footer" slot="footer">
        <el-button @click="post_inviteeFeedback"
                   style="width: 140px; height: 50px; border: 1px solid #707070; border-radius: 131px; ">
          {{$t('myProject.submit')}}<!--提交-->
        </el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import {customToDate, dDateValue} from "@/utils/dateUtils"
    import CustomButton from "@/components/my_project/vip_private_recommendation/CustomButton";
    import {selectCountriesCodeInfo} from '@/api/userApi'
    import {
        myJoinprojectDetails,
        inviteeFeedback,
        inviteeEvaluate,
        inviteeCancel,
        myJoinmultiProDetails,
        multiInviteeFeedback,
        multiInviteeCancel,
    } from "@/api/projectAbout";
    import {inviteeEvaluateEdit} from "@/api/myProjectApi";

    const cityOptions = ["上海", "北京", "广州", "深圳"];
    export default {
        name: "AppointmentTime",
        data() {
            return {
                isEditing: false,
                ratingEdit: 0,
                contentEdit: '',
                commentTxtVal: 0,//文本域计数
                areaList: [],
                star: null,
                txtVal: 0, //文本域计数
                txtContent: "",
                checkedCities: ["上海"],
                cities: cityOptions,
                colors: ["#000000", "#F7BA2A", "#000000"],
                // details_data: {},
                select_data: "",
                remarks: "",
                dialogVisible: false,
                pd_post: 0, //        0 同意/1 拒绝
                many: {
                    data_mailbox: "",
                    phone: "",
                    remarks: "",
                    data_whatsApp: "",
                    content: "",
                    mobileCode: "86",
                    whatsappCode: "86",
                },
            };
        },
        props: {
            details_data: {
                default: {},
            },
            type: {
                // type: Number,
                default: 2,
            },
            isEvaluate: {
                // type: Number,
                default: 0,
            },
            dataType: {
                // type: Number,
                default: 2,
            },
        },
        components: {
            CustomButton,
        },
        mounted() {
            //获取地区编号
            this.getArea();
            //   获取我参与的详情
            this.get_project();
            dDateValue(customToDate("15/10/2021 10:45:00"), new Date());

        },
        methods: {
            isCan(value) {
                return dDateValue(customToDate(value), new Date()) < 0;
            },
            formatCode(code, en, cn) {
                if (this.$i18n.locale == 'zh') {
                    return "+" + code + "(" + cn + ")";
                } else {
                    return "+" + code + "(" + en + ")";

                }
            },
            async editComment() {
                if (this.isEditing) {
                    let formData = {
                        content: this.contentEdit,
                        dataType: this.dataType,
                        interviewId: this.details_data.interview_id,
                        rating: this.ratingEdit,
                        token: localStorage.getItem("token"),
                    };
                    let res = await inviteeEvaluateEdit(formData);
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                        this.get_project();
                        this.isEditing = !this.isEditing
                    } else {
                        this.$message.error(res.msg);
                    }
                } else {

                    this.contentEdit = this.details_data.comment_content;
                    this.ratingEdit = parseInt(this.details_data.rating);
                    this.isEditing = !this.isEditing
                    this.txtChange();
                }
            },

            // 提交评论
            async post_comment() {
                let formData = {
                    token: localStorage.getItem("token"),
                    dataType: this.$route.query.pd_num,
                    projectId: this.$route.query.project_Id,
                    mobileNumber: this.many.phone,
                    mailBox: this.many.data_mailbox,
                    whatsApp: this.many.data_whatsApp,
                    content: this.many.content,
                    rating: this.star,
                };
                let res = await inviteeEvaluate(formData);
                if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                    // this.$parent.get_projectDetails();
                    location.reload();

                } else {
                    this.$message.error(res.msg)
                }
            },
            //获取地区
            getArea() {
                selectCountriesCodeInfo().then((res) => {
                    if (res.code == 200) {
                        console.log("countrysChinese")
                        console.log(res.data)
                        this.areaList = res.data;

                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //   选择方法
            select_fun(key) {
                this.select_data = key;
            },
            // //   受邀人邀约取消
            // async post_inviteeCancel() {
            //   let formData = {
            //     token: localStorage.getItem("token"),
            //     dataType: this.$route.query.pd_num,
            //     projectId: this.$route.query.project_Id,
            //     mobileNumber: this.many.phone,
            //     mailBox: this.many.data_mailbox,
            //     whatsApp: this.many.data_whatsApp,
            //     cancelReason: this.many.content,
            //   };
            // },
            //   受邀人邀约反馈提交
            async post_inviteeFeedback() {
                let formData = {
                    token: localStorage.getItem("token"),
                    dataType: this.$route.query.pd_num,
                    projectId: this.$route.query.project_Id,
                    mobileNumber: this.many.phone,
                    mailBox: this.many.data_mailbox,
                    whatsApp: this.many.data_whatsApp,
                    mobileCode: this.many.mobileCode,
                    whatsappCode: this.many.whatsappCode,
                    pdPost: this.pd_post,
                };
                let message_data = "";
                if (this.pd_post == 0 || this.pd_post == 1) {
                    if (this.pd_post == 0) {
                        formData.feedback = 1;
                        formData.invitationTime = this.select_data;
                        formData.messageContent = this.many.remarks;
                        message_data = this.$t('bulletFrame.agreeToMakeAnAppointment')/*"同意预约成功"*/;
                    } else if (this.pd_post == 1) {
                        formData.rejectReason = this.many.content;
                        formData.feedback = 2;
                        message_data = this.$t('myProject.recommendTableMsg.refuseSucess')/*"拒绝预约成功"*/;
                    }
                    let res = await inviteeFeedback(formData);
                    if (res.code == 200) {
                        this.dialogVisible = false;
                        this.$message({
                            message: message_data,
                            type: "success",
                        });
                        setTimeout(() => {
                            location.reload();
                        }, 800);
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "warning",
                        });
                    }
                } else if (this.pd_post == 2) {
                    if (this.$route.query.pd_num == "6") {
                        this.$delete(formData, "projectId");
                        this.$delete(formData, "dataType");
                        formData.joinId = this.$route.query.joinId;
                        formData.cancelReason = this.many.content;
                        let res = await multiInviteeCancel(formData);
                        if (res.code == 200) {
                            this.$message({
                                message: this.$t('myProject.recommendTableMsg.cancelSucess')/*"取消预约成功"*/,
                                type: "success",
                            });
                            setTimeout(() => {
                                location.reload();
                            }, 800);
                        } else {
                            this.$message.warning(res.msg);
                        }
                    } else {
                        formData.cancelReason = this.many.content;
                        let res3 = await inviteeCancel(formData);
                        if (res3.code == 200) {
                            this.dialogVisible = false;
                            this.$message({
                                message: this.$t('myProject.recommendTableMsg.cancelSucess')/*"取消预约成功"*/,
                                type: "success",
                            });
                            setTimeout(() => {
                                location.reload();
                            }, 800);
                        } else {
                            this.$message({
                                message: res3.msg,
                                type: "warning",
                            });
                        }
                    }
                }
            },
            //   获取我参与的详情
            async get_project() {
                let formData = {
                    token: localStorage.getItem("token"),
                    dataType: this.$route.query.pd_num,
                    mainId: this.$route.query.project_Id,
                };
                let res = "";
                if (this.$route.query.pd_num == "6") {
                    formData.joinId = this.$route.query.joinId;
                    formData.projectId = this.$route.query.project_Id;
                    res = await myJoinmultiProDetails(formData);
                } else {
                    formData.mainId = this.$route.query.project_Id;
                    res = await myJoinprojectDetails(formData);
                }
                console.log("hahahahh", res.code)

                if (res.code == 200) {
                    if (res.data.rating) {
                        this.star = parseInt(res.data.rating);
                    } else {
                        this.star = null;
                    }
                    this.details_data = res.data;
                    console.log("hahahahh", this.details_data)
                    this.ratingEdit = parseInt(this.details_data.rating);
                }
            },
            async sendMsg(key) {
                let formData = {
                    token: localStorage.getItem("token"),
                    joinId: this.$route.query.joinId,
                    mobileNumber: this.many.phone,
                    mailBox: this.many.data_mailbox,
                    whatsApp: this.many.data_whatsApp,
                    mobileCode: this.many.mobileCode,
                    whatsappCode: this.many.whatsappCode,
                };
                let message_data = "";
                if (key == 1) {
                    formData.feedback = key;
                    formData.messageContent = this.many.content;
                    message_data = this.$t('bulletFrame.agreeToMakeAnAppointment')/*"预约成功"*/;
                } else {
                    formData.feedback = key;
                    formData.rejectReason = this.many.content;
                    message_data = this.$t('myProject.recommendTableMsg.refuseSucess')/*"拒绝成功"*/;
                }
                let res = await multiInviteeFeedback(formData);
                if (res.code == 200) {
                    this.$message({
                        message: message_data,
                        type: "success",
                    });
                    setTimeout(() => {
                        location.reload();
                    }, 800);
                } else {
                    this.$message.error(res.msg)
                }
            },
            txtChange() {
                // this.txtVal = this.details_data.comment_content;
                this.txtVal = this.many.content.length;
                // this.commentTxtVal = this.contentEdit.length;


            },
            txtChangeForEdit() {

                this.commentTxtVal = this.contentEdit.length;


            },
        },
    };
</script>
<style>
    .title-class .el-dialog__title {
        width: 96px;
        height: 31px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
    }
</style>

<style scoped>
    .gray {
        background-color: gray;
    }

    .appointment-time {
        margin-top: 54px;
    }

    .appointment-time > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .appointment-time > .tip {
        margin-top: 7px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
    }

    .time-list {
        margin-top: 23px;
    }

    .time-list > li {
        margin-bottom: 26px;
    }

    .time-list > li:last-child {
        margin-bottom: 0;
    }

    .leave-message {
        margin-top: 55px;
    }

    .leave-message > h1 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .leave-message textarea {
        width: 695px;
        height: 117px;
        background: #fafafa;
        border-radius: 4px;
        margin-top: 21px;
        resize: none;
        padding: 17px 26px;
        box-sizing: border-box;
    }

    .is-btn-group {
        margin-top: 40px;
        overflow: hidden;
    }

    .radio-label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    /*111111111111*/
    .appointment-time .time {
        margin-top: 7px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .s-leave-message {
        margin-top: 30px;
    }

    .s-leave-message > h2 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .s-leave-message > p {
        margin-top: 7px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
    }

    .cancel-yy {
        margin-top: 54px;
    }

    .cancel-yy .btn {
        display: inline-block;
    }

    .cancel-yy > span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 50px;
        color: #949494;
        margin-left: 33px;
    }

    /*1111111111111*/
    .form-wrap {
        margin-top: 57px;
    }

    .input-text-area {
        margin-bottom: 31px;
    }

    .input-text-area:last-child {
        margin-bottom: 0;
    }

    .ita-label {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
    }

    .ita-label > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .ita-input-front {
        width: 536px;
        height: 50px;
        background: #fafafa;
        border-radius: 131px;
        margin-top: 22px;
        padding: 0 26px;
        line-height: 50px;
    }

    .ita-input-fronts {
        width: 300px;
        height: 50px;
        background: #fafafa;
        border-radius: 131px;
        margin-top: 22px;
        padding: 0 50px;
        line-height: 50px;
    }

    .s-input {
        display: inline-block;
        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 60px;
    }

    .v-line {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 22px;
        border: 1px solid #d6d6d6;
        margin-right: 45px;
    }

    .ita-input-front > span {
        display: inline-block;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .iif-input {
        display: inline-block;

        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 300px;
    }

    .people-wrap {
        height: 49px;
        margin-top: 31px;
        line-height: 49px;
    }

    .people-wrap .people-head {
        display: inline-block;
        width: 49px;
        height: 49px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-wrap .people-name {
        display: inline-block;

        font-size: 15px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-left: 12px;
    }

    .people-wrap .project-name {
        display: inline-block;

        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-left: 54px;
    }

    .evaluate {
        margin-top: 36px;
    }

    .evaluate-star {
        height: 25px;
        line-height: 25px;
    }

    .evaluate-star > span {
        display: inline-block;
        line-height: 25px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 30px;
    }

    .evaluate-star > .star {
        display: inline-block;
        line-height: 20px;
    }

    .evaluate-content {
        width: 695px;
        height: 121px;
        background: #fafafa;
        border-radius: 4px;
        margin-top: 30px;
        position: relative;
    }

    .evaluate-content textarea {
        width: 99%;
        height: 90px;
        background-color: transparent;
        resize: none;
        padding: 17px 26px;

        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .numberV {
        position: absolute;
        right: 24px;
        bottom: 13px;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .evaluate-result {
        margin-top: 57px;
        padding: 27px 0 57px 0;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        position: relative;
    }

    .evaluate-result > .edit {
        position: absolute;
        right: 26px;
        top: 32px;
        width: 83px;
        height: 34px;
        border: 1px solid #949494;
        border-radius: 131px;
        text-align: center;
        line-height: 34px;
        cursor: pointer;

    }

    .evaluate-result > .edit > img {
        width: 12px;
    }

    .evaluate-result > .edit > span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-left: 6px;
    }

    .evaluate-result > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .evaluate-result-content {
        overflow: hidden;
        margin-top: 30px;
    }

    .evaluate-result-content > img {
        float: left;
        width: 67px;
        height: 67px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .erc-r {
        margin-left: 13px;
        padding-top: 8px;
    }

    .erc-r-t {
        width: 988px;
        height: 60px;
    }

    .erc-r-t-l {
        float: left;
        height: 60px;
    }

    .erc-r-t-l-name {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 20px;
        color: #000000;
        margin-bottom: 14px;
    }

    .erc-r-t-r {
        float: right;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 60px;
    }

    .erc-r-b {
        margin-top: 20px;
        width: 988px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 23px;
        color: #707070;
    }

    /*radio样式*/
    input[type="radio"] {
        /*同样，首先去除浏览器默认样式*/
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /*编辑我们自己的样式*/
        position: relative;
        width: 16px;
        height: 16px;
        bottom: 3px;
        margin-right: 14px;

        background: transparent;
        border: 1px solid #8a8a8a;
        /*border-radius: 4px;*/
        outline: none;
        cursor: pointer;
    }

    input[type="radio"] {
        /*同样，首先去除浏览器默认样式*/
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /*编辑我们自己的样式*/
        position: relative;
        background: url("../../../assets/images/radio_n@2x.png") no-repeat;
        background-size: 100% 100%;
        border: 0;
    }

    input[type="radio"]:after {
        content: "";
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        text-align: center;
        line-height: 18px;
    }

    input[type="radio"]:checked:after {
        border-radius: 0;
        opacity: 1;
    }

    input[type="radio"]:checked {
        background: url("../../../assets/images/radio_s@2x.png") no-repeat;
        background-size: 100% 100%;
    }
</style>
