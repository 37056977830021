// 15/10/2021 10:45:00时间格式解析
export const customToDate = function (val) {
    let list1 = val.split(' ');
    let date = list1[0];
    let time = list1[1];

    let list2 = date.split('/');
    let year = list2[2];
    let month = list2[1];
    let day = list2[0];

    let list3 = time.split(':');
    let h = list3[0];
    let m = list3[1];
    let s = list3[2];

    // console.log("时间结果", new Date(year, month-1, day, h, m, s))
    // console.log("时间结果", year, month-1, day, h, m, s)
    return new Date(year, month - 1, day, h, m, s);
}
//计算两个时间相差毫秒数
export const dDateValue = function (date1, date2) {
    console.log("时间时间")
    console.log(date1, date2)
    let time1 = date1.getTime();
    let time2 = date2.getTime();
    // console.log("时间", date1, date2)
    // console.log("时间戳", time1, time2)
    console.log("时间戳差", (time1 - time2) / (1000 * 60 * 60))
    return (time1 - time2) / (1000 * 60 * 60);

}
