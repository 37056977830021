<template>
    <div class="container">
        <BreadcrumbNav :page-name="$t('vipRecommend.detailsOfMyProjects')"/>

        <div class="main">
            <div :style="{'min-height':details_data.dataType == 4 || $route.query.pd_num == 6 ? '173px' : '',}"
                 class="text-group1">
                <h1 v-if="details_data.dataType == 1">
                    {{ $t("myProject.iAmInterestedInFreeCommunicationProjects") }}
                </h1>
                <h1 v-else-if="details_data.dataType == 2">
                    {{ $t("myProject.vipPrivateRecommendation") }}
                </h1>
                <h1 v-else-if="details_data.dataType == 3">
                    {{ $t("myProject.itemsIRecommendToOtherMembers") }}
                </h1>
                <h1 v-else-if="details_data.dataType == 4">
                    {{ $t("myProject.otherMembersRecommendProjectsToMe") }}
                </h1>
                <h1 v-else-if="details_data.dataType == 5">
                    {{ $t("myProject.projectsIBrowsed") }}
                </h1>
                <h1 v-if="$route.query.pd_num == 6">
                    {{ details_data.invitee_source_name||$t("myProject.multiPersonCommunication") }}
                </h1>
                <div style="display: flex; align-items: center; margin-top: 19px" v-show="details_data.dataType == 4">
                    <img :src="details_data.referrer_avatar" alt=""
                         style="width: 49px; height: 49px; border-radius: 50%"/>
                    <div style="margin-left: 12px">
                        <h2>{{ details_data.referrer_name }}</h2>
                        <h2 style="color: #949494; margin-top: 6px">{{$t('myProject.recommendPerson')}}<!--推荐人--></h2>
                    </div>
                </div>
                <h2>{{ details_data.progress != 10 ? details_data.progress_name : details_data.is_evaluate == 0 ?
                    this.$t('myInterviewDetail.immediateEvaluation') : this.$t('myInterviewDetail.haveEvaluation')
                    }}</h2>
            </div>
            <div class="text-group2">
                <h1>{{ details_data.project_name }}</h1>
                <div class="text-group2-content">
                    <span class="publish-date">{{ details_data.project_intro }}</span>
                </div>
            </div>
            <div class="people-wrap" v-if="details_data.is_hidden_issuer == '1'">
                <div class="people-info fl">
                    <img :src="details_data.avatar" alt="" class="head-icon fl"/>
                    <div class="people-info-text fl">
                        <h1 class="over">{{ details_data.name }}</h1>
                        <h2 class="over">
                            {{ details_data.company_name }} · {{ details_data.position }}
                        </h2>
                    </div>
                </div>
                <div class="btn-group fl">
                    <CustomButton @click.native="$router.push({
           path: '/user/index',query:{userType:2, talentId:details_data.talent_id,}})" class="fl" type="black">
                        {{ $t("project.buyNow") }}
                    </CustomButton>
                    <CustomButton @click.native="attention(2, details_data.talent_id,  details_data.name) " class="fl"
                                  style="margin-left: 13px" type="white"
                                  v-if="details_data.followed == 1">
                        {{ $t("myProject.unfollow") }}
                    </CustomButton>
                    <CustomButton @click.native="attention(1, details_data.talent_id,  details_data.name) " class="fl"
                                  style="margin-left: 13px" type="white-line"
                                  v-else>
                        {{ $t("myInterviewDetail.attention") }}
                    </CustomButton>
                </div>
            </div>
            <div v-if="pl_type == 4">
                <MultiPerson :list="details_data.rosterList" :talentId="details_data.talentId||''"/>
            </div>
            <!-- 翻译：推荐原因-->
            <div class="reason" v-if="details_data.dataType == 2">
                <h1>{{ $t("vipRecommend.reasonsForRecommendation") }}</h1>
                <!--<p class="over4">
                  {{ details_data.recommend_reason }}
                </p>-->
                <div class="rich-text blog1" style="padding-top: 40px">
                    <div v-html="details_data.recommend_reason"></div>
                </div>
            </div>
            <div class="is-btn-group"
                 v-if="(delivery_data.pd_num==2|| delivery_data.pd_num==4)&&(type == 0 || type == -1)">
                <div @click="dialogVisible = true; pd_post = 1;">
                    <CustomButton class="fl" type="black">{{ $t("myProject.willingToCommunicateForFree") }}
                    </CustomButton>
                </div>
                <div @click="dialogVisible = true; pd_post = 2;">
                    <CustomButton class="fl" style="margin-left: 15px" type="white-line">
                        {{ $t("myInterviewDetail.refuse") }}
                    </CustomButton>
                </div>
            </div>
            <div class="is-btn-group" v-else-if="type === 1">
                <CustomButton type="ban">{{ $t("myInterviewDetail.YouHaveBeenWillingToCommunicateForFree") }}
                </CustomButton>
            </div>
            <div class="is-btn-group" v-else-if="type === 2">
                <CustomButton type="ban">{{ $t("myProject.rejected") }}</CustomButton>
            </div>
            <div class="is-btn-group" v-else-if="type === 4">
                <CustomButton type="ban">{{ $t("myInterviewDetail.ThePublisherHasBeenCancelled") }}</CustomButton>
            </div>
            <div class="is-btn-group" v-else-if="type === 5">
                <CustomButton type="ban">{{ $t("myInterviewDetail.YouHaveToCancel") }}</CustomButton>
            </div>
            <div class="reason" v-if="type === 2">
                <!-- 翻译：拒绝原因-->
                <h1>{{ $t("vipRecommend.reasonsForRefuse") }}</h1>
                <p class="over4">{{ details_data.reject_reason }}</p>
            </div>
            <div class="reason" v-if="type === 4 || type === 5">
                <!-- 翻译：拒绝原因-->
                <h1>{{ $t("vipRecommend.publisherCancelsReason") }}</h1>
                <!-- <p class="over4">
                  发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因发布者取消原因
                </p> -->
                <p class="over4" v-if="details_data.cancellation_reason">
                    {{ details_data.cancellation_reason }}
                </p>
                <p class="over4" v-else-if="details_data.reject_reason">
                    {{ details_data.reject_reason }}
                </p>
            </div>
            <!--预约时间-->
            <AppointmentTime :data-type="details_data.dataType" :details_data="details_data"
                             :is-evaluate="details_data.is_evaluate" :type="pl_type" v-if="type === 3"/>

            <div class="project-detail">
                <!-- 翻译：项目详情-->
                <h1>{{ $t("vipRecommend.projectDetail") }}</h1>
                <div class="rich-text">
                    <p v-html="details_data.details"></p>
                    <!-- <img src="@/assets/images/rich_content.png" style="width: 695px" alt="" /> -->
                </div>
            </div>
            <!--'同意预约' : '拒绝原因'"-->
            <el-dialog
                    :title="pd_post == 1 ? this.$t('bulletFrame.agreeToMakeAnAppointment') : this.$t('vipRecommend.reasonsForRefuse')"
                    :visible.sync="dialogVisible"
                    center custom-class="title-class" width="700px">
                <div style="display: flex; justify-content: center">
                    <div class="form-wrap">
                        <div class="input-text-area">
                            <div class="ita-label">
                                <span>* </span>{{ $t("vipRecommend.mobileNumber") }}
                            </div>

                            <div class="area-code" style="margin-top: 15px;width: 536px">
                                <el-input :placeholder="$t('publish.input')" class="input-with-select"
                                          v-model="many.phone">
                                    <el-select :placeholder="$t('message.pleaseSelect')" filterable slot="prepend"
                                               v-model="many.mobileCode">
                                        <el-option :key="item.id"
                                                   :label="'+'+item.code + '(' + item.countrysEnglish + ')'"
                                                   :value="item.code"
                                                   v-for="item in areaList.phoneCountriesCodeList"></el-option>
                                    </el-select>
                                </el-input>
                            </div>
                        </div>
                        <div class="input-text-area">
                            <div class="ita-label"><span>* </span>{{ $t("vipRecommend.email") }}</div>
                            <div class="ita-input-front">
                                <input :placeholder="$t('publish.input')" class="iif-input" type="text"
                                       v-model="many.data_mailbox"/>
                            </div>
                        </div>
                        <div class="input-text-area">
                            <div class="ita-label"><span>* </span>WhatsApp</div>

                            <div class="area-code" style="margin-top: 15px;width: 536px">
                                <el-input :placeholder="$t('publish.input')" class="input-with-select"
                                          v-model="many.data_whatsApp">
                                    <el-select :placeholder="$t('message.pleaseSelect')" filterable slot="prepend"
                                               v-model="many.whatsappCode">
                                        <el-option :key="item.id"
                                                   :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)"
                                                   :value="item.code"
                                                   v-for="item in areaList.phoneCountriesCodeList"></el-option>
                                    </el-select>
                                </el-input>
                            </div>

                            <div class="input-text-area" v-if="pd_post == 2">
                                <div class="ita-input-front" style="border-radius: 4px; height: 100%">
                  <textarea :placeholder="$t('bulletFrame.cancelReason')" cols="30" id="evaluate" name="evaluate"
                            rows="10" style="
                      background-color: #fafafa;
                      width: 100%;
                      height: 149px;
                      margin-top: 17px;
                    " v-model="many.content"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span class="dialog-footer" slot="footer">
          <el-button @click="post_referralFeedback()" style="
              width: 140px;
              height: 50px;
              border: 1px solid #707070;
              border-radius: 131px;
            ">{{ $t("home.submit") }}</el-button>
        </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import CustomButton from "@/components/my_project/vip_private_recommendation/CustomButton";
    import AppointmentTime from "@/components/my_project/vip_private_recommendation/AppointmentTime";
    import MultiPerson from "@/components/my_project/MultiPersonFreeCommunication";
    import {
        myJoinprojectDetails,
        myJoinmultiProDetails,
        inviteeFeedback,
        referralFeedback,
        multiInviteeFeedback,
    } from "@/api/projectAbout";
    import {followTalent} from "@/api/memberApi";
    import {selectCountriesCodeInfo} from '@/api/userApi'

    export default {
        name: "index",
        components: {
            BreadcrumbNav,
            CustomButton,
            AppointmentTime,
            MultiPerson,
        },
        data() {
            return {
                type: 0, //0:未选择、1：同意、2：拒绝、3：预约时间、4：发布者已取消、5：您已取消
                pl_type: 0, //0:选择时间、1：预约成功、2：预约评论、3：显示评论、4：多人评论
                stateObject: {
                    isOnly: true,
                    communicateType: 0,
                },
                delivery_data: {},
                details_data: {},
                pd_post: 0,
                dialogVisible: false,
                areaList: [],
                many: {
                    data_mailbox: "",
                    phone: "",
                    remarks: "",
                    data_whatsApp: "",
                    content: "",
                    mobileCode: "86",
                    whatsappCode: "86",
                },
            };
        },
        mounted() {
            //获取地区编号
            this.getArea();
            this.delivery_data = this.$route.query;
            this.get_projectDetails();
        },
        methods: {
            formatCode(code, en, cn) {
                if (this.$i18n.locale == 'zh') {
                    return "+" + code + "(" + cn + ")";
                } else {
                    return "+" + code + "(" + en + ")";

                }
            },
            //获取地区
            getArea() {
                selectCountriesCodeInfo().then((res) => {
                    if (res.code == 200) {
                        this.areaList = res.data;
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //关注
            attention(type, id, name) {
                let formData = {
                    operateType: type,
                    talentId: id,
                    talentName: name,
                    token: localStorage.getItem('token')
                }
                followTalent(formData).then((res) => {
                    if (res.code == 200) {
                        if (type == 1) {
                            this.details_data.followed = 1
                            this.$message.success(this.$t("myProject.followMsg.followSuccess"))
                        } else {
                            this.details_data.followed = 0
                            this.$message.success(this.$t("myProject.followMsg.unfollowSuccess"))
                        }
                    }
                })
            },
            // 被推荐会员 反馈提交
            async post_referralFeedback() {
                let formData = {
                    token: localStorage.getItem("token"),
                    dataType: this.delivery_data.pd_num,
                    mobileNumber: this.many.phone,
                    mailBox: this.many.data_mailbox,
                    whatsApp: this.many.data_whatsApp,
                    sourceType: 1,
                    rejectReason: this.many.content,
                    mobileCode: this.many.mobileCode,
                    whatsappCode: this.many.whatsappCode,
                };
                if (this.pd_post == 1) {
                    formData.feedback = 1;
                } else {
                    formData.feedback = 2;
                    formData.rejectReason = this.many.content;
                }
                if (this.delivery_data.pd_num == 6) {
                    formData.joinId = this.delivery_data.joinId;
                    let res = await multiInviteeFeedback(formData);
                    if (res.code == 200) {
                        if (this.pd_post == 1) {
                            this.$message({
                                message: this.$t('bulletFrame.agreeToMakeAnAppointment')/*"同意预约成功"*/,
                                type: "success",
                            });
                        } else {
                            this.$message({
                                message: this.$t('myProject.recommendTableMsg.refuseSucess')/*"拒绝预约成功"*/,
                                type: "success",
                            });
                        }
                        setTimeout(() => {
                            location.reload();
                        }, 800);
                    } else {
                        this.$message.warning(res.msg)
                    }
                } else {
                    formData.projectId = this.delivery_data.project_Id;
                    let res = await referralFeedback(formData);
                    if (res.code == 200) {
                        if (this.pd_post == 1) {
                            this.$message({
                                message: this.$t('bulletFrame.agreeToMakeAnAppointment')/*"同意预约成功"*/,
                                type: "success",
                            });
                        } else {
                            this.$message({
                                message: this.$t('myProject.recommendTableMsg.refuseSucess')/*"拒绝预约成功"*/,
                                type: "success",
                            });
                        }
                        setTimeout(() => {
                            location.reload();
                        }, 800);
                    } else {
                        this.$message.warning(res.msg)
                    }
                }
            },
            //   项目详情
            async get_projectDetails() {
                let formData = {
                    token: localStorage.getItem("token"),
                    interviewId: this.delivery_data.interviewId,
                };
                if (this.delivery_data.pd_num == "6") {
                    formData.joinId = this.delivery_data.joinId;
                    formData.projectId = this.delivery_data.project_Id;
                    this.pl_type = 4;
                    let res = await myJoinmultiProDetails(formData);
                    if (res.code == 200) {
                        this.details_data = res.data;
                        if (res.data.progress) {
                            switch (res.data.progress) {
                                case "1":
                                    this.type = 3;
                                    this.pl_type = 4;
                                    break;
                                // 拒绝
                                case "2":
                                    this.type = 5;
                                    break;
                                // 预约成功
                                case "3":
                                    this.type = 3;
                                    this.pl_type = 1;
                                    break;
                                // 取消邀约
                                case "4":
                                    this.type = 5;
                                    break;
                            }
                        }
                    }
                } else {
                    formData.mainId = this.delivery_data.project_Id;
                    formData.dataType = this.delivery_data.pd_num;
                    this.pl_type = 0;
                    myJoinprojectDetails(formData).then((res) => {
                        this.details_data = res.data;
                        //   res.data.progress = "10";
                        if (res.data.progress) {
                            switch (res.data.progress) {
                                // 待被推荐人反馈
                                case "-1":
                                    this.type = -1;
                                    break;
                                // 被推荐人拒绝
                                case "0":
                                    this.type = 5;
                                    break;
                                //  选择预约时间 判断类型是不是浏览过
                                case "1":
                                    this.type = 0;
                                    if (res.data.dataType == 5) {
                                        this.type = null;
                                    } else if (res.data.dataType == 4) {
                                        this.type = 1;
                                    }
                                    break;
                                case "2":
                                    this.type = 2;
                                    break;
                                // 选择时间········
                                case "3":
                                    this.type = 3;
                                    if (res.data.dataType == 2) {
                                        this.type = 1;
                                    }
                                    break;
                                // 拒绝·········
                                case "4":
                                    this.type = 2;
                                    break;
                                // 预约成功··········
                                case "5":
                                    if (res.data.dataType == 2) {
                                        this.type = 3;
                                    } else {
                                        this.type = 3;
                                        this.pl_type = 1;
                                    }
                                    break;
                                // 发布者取消
                                case "6":
                                    if (res.data.dataType == 2) {
                                        this.type = 2;
                                    } else {
                                        this.type = 4;
                                    }
                                    break;
                                // 参与者取消
                                case "7":
                                    if (res.data.dataType == 2) {
                                        this.type = 3;
                                        this.pl_type = 1;
                                    } else {
                                        this.type = 5;
                                    }
                                    // this.type = 3;
                                    // this.pl_type = 2;
                                    break;
                                case "8":
                                    if (res.data.dataType == 2) {
                                        this.type = 4;
                                    } else {
                                        this.type = 3;
                                        this.pl_type = 2;
                                    }

                                    break;
                                // 受邀人评论·······
                                case "9":
                                    if (res.data.dataType == 2) {
                                        this.type = 5;
                                    } else {
                                        this.type = 3;
                                        this.pl_type = 2;
                                    }

                                    break;
                                // 受邀人已经评论···········
                                case "10":
                                    if (res.data.dataType == 2) {
                                        this.type = 3;
                                        if (res.data.is_evaluate == 0) {
                                            this.pl_type = 2;

                                        } else {
                                            this.pl_type = 3;
                                        }
                                    } else {
                                        this.type = 3;
                                        this.pl_type = 3;
                                    }
                                    break;
                            }
                        }
                    });
                }
            },
        },
    };
</script>
<style scoped>
    .blog1 ::v-deep.ql-video {
        width: 650px;
        height: 325px;
    }

    .title-class .el-dialog__title {
        width: 96px;
        height: 31px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
    }
</style>
<style scoped>
    /*1111111111111*/
    .iif-input {
        display: inline-block;

        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 300px;
    }

    .form-wrap {
        margin-top: 57px;
    }

    .input-text-area {
        margin-bottom: 31px;
    }

    .input-text-area:last-child {
        margin-bottom: 0;
    }

    .ita-label {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
    }

    .ita-label > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .ita-input-front {
        width: 536px;
        height: 50px;
        background: #fafafa;
        border-radius: 131px;
        margin-top: 22px;
        padding: 0 26px;
        line-height: 50px;
    }

    .s-input {
        display: inline-block;
        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 60px;
    }

    .v-line {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 22px;
        border: 1px solid #d6d6d6;
        margin-right: 45px;
    }

    .container {
        background-color: #ffffff;
    }

    .main {
        width: 1200px;
        padding-top: 14px;
        margin: 0 auto;
    }

    .text-group1 {
        box-sizing: border-box;
        width: 100%;
        min-height: 120px;
        border-bottom: 1px solid #e5e5e5;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .text-group1 > h1 {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .text-group1 > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-top: 8px;
    }

    .text-group2 {
        box-sizing: border-box;
        width: 100%;
        min-height: 120px;
        border-bottom: 1px solid #e5e5e5;
        padding-top: 30px;
    }

    .text-group2-content {
        margin-top: 8px;
    }

    .publish-date {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .brief-intro {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-left: 16px;
    }

    .text-group2 > h1 {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .people-wrap {
        padding: 38px 0;
        overflow: hidden;
    }

    .people-wrap .head-icon {
        width: 67px;
        height: 67px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-info-text {
        margin-left: 13px;
        min-width: 160px;
        max-width: 300px;
    }

    .people-info-text > h1 {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-top: 8px;
    }

    .people-info-text > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        margin-top: 13px;
        margin-left: 4px;
    }

    .people-wrap .btn-group {
        margin-top: 4px;
        margin-left: 136px;
    }

    .reason {
        margin-top: 65px;
    }

    .reason > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .reason > p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-top: 10px;
    }

    .is-btn-group {
        margin-top: 38px;
        overflow: hidden;
    }

    .project-detail {
        margin-top: 72px;
    }

    .project-detail > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .project-detail .rich-text {
        margin-top: 42px;
    }

    .ita-input-fronts {
        width: 300px;
        height: 50px;
        background: #fafafa;
        border-radius: 131px;
        margin-top: 22px;
        padding: 0 50px;
        line-height: 50px;
    }
</style>
